<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialog" persistent hide-overlay>
			<v-card color="primary" dark>
				<v-card-text class="pa-5">
					Aguarde seu arquivo está sendo processado.
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-snackbar color="primary" v-model="snackbar.status" :timeout="snackbar.timeout" centered dark>
            <v-icon class="mr-1" size="21px">mdi-alert-circle</v-icon>
            Nenhum arquivo a ser gerado para o filtro informado.
        </v-snackbar>
		<v-col cols="12" md="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row class="mb-n10">
							<v-col class="pl-2 pr-1">
								<v-autocomplete
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col class="d-flex pl-1 pr-2">
								<InputDatePicker
									:disabled="dialog"
									v-model="busca.dtmes"
									label="Selecione o mês"
									:outlined="true"
									:dense="true"
									type="mounth"
								/>
								<v-btn
									:loading="dialog"
									class="ml-2"
									height="39"
									large
									color="primary"
									elevation="0"
									@click="gerarArquivo()"
								>
									<v-icon class="mr-sm-3">mdi-file-plus-outline</v-icon>
									<span class="d-none d-sm-block">Gerar arquivo</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col>
			<v-card>
				<v-card-text v-if="dados.length > 0">
					<v-simple-table>
						<thead>
							<tr>
								<th>Cód. Filial</th>
								<th>Vendedor</th>
								<th>Meta</th>
								<th>Venda Base</th>
								<th>Comissão Base</th>
								<th v-if="dados.filter(v => v.comissaocoralegre > 0).length > 0">Venda Coralegre</th>
								<th v-if="dados.filter(v => v.comissaocoralegre > 0).length > 0">Comissão Coralegre</th>
								<th v-if="dados.filter(v => v.comissaocampanha > 0).length > 0">Venda Campanha</th>
								<th v-if="dados.filter(v => v.comissaocampanha > 0).length > 0">Comissão Campanha</th>
                <th v-if="dados.filter(v => v.comissaocampanhaob > 0).length > 0">Venda Obsoletos</th>
                <th v-if="dados.filter(v => v.comissaocampanhaob > 0).length > 0">Comissão Obsoletos</th>
                <th v-if="dados.filter(v => v.vendatopcampanhaob > 0).length > 0">Top 50 Obsoletos</th>
                <th v-if="dados.filter(v => v.comissaotopcampanhaob > 0).length > 0">Comissão Top 50 Obsoletos</th>
								<th>Salário</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(u,i) in dados" :key="i">
								<td>{{u.idfilial}}</td>
								<td class="text-truncate">{{u.vendedor}}</td>
								<td>{{u.meta | formataDinheiro}}</td>
								<td>{{u.vendabase | formataDinheiro}}</td>
								<td>{{u.comissaobase | formataDinheiro}}</td>
								<td
									v-if="dados.filter(v => v.comissaocoralegre > 0).length > 0"
								>{{u.vendacoralegre | formataDinheiro}}</td>
								<td
									v-if="dados.filter(v => v.comissaocoralegre > 0).length > 0"
								>{{u.comissaocoralegre | formataDinheiro}}</td>
								<td
									v-if="dados.filter(v => v.comissaocampanha > 0).length > 0"
								>{{u.vendacampanha | formataDinheiro}}</td>
								<td
									v-if="dados.filter(v => v.comissaocampanha > 0).length > 0"
								>{{u.comissaocampanha | formataDinheiro}}</td>
                <td
                    v-if="dados.filter(v => v.comissaocampanhaob > 0).length > 0"
                >{{u.vendacampanhaob | formataDinheiro}}</td>
                <td
                    v-if="dados.filter(v => v.comissaocampanhaob > 0).length > 0"
                >{{u.comissaocampanhaob | formataDinheiro}}</td>
                <td
                    v-if="dados.filter(v => v.vendatopcampanhaob > 0).length > 0"
                >{{u.vendatopcampanhaob | formataDinheiro}}</td>
                <td
                    v-if="dados.filter(v => v.comissaotopcampanhaob > 0).length > 0"
                >{{u.comissaotopcampanhaob | formataDinheiro}}</td>
								<td>{{u.salario | formataDinheiro}}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr class="font-weight-bold">
								<td class="text-truncate" colspan="2">Total</td>
								<td
									class="text-truncate"
								>{{dados.filter(v => v.meta).map(v => v.meta).reduce((a,v) => a + v) |formataDinheiro}}</td>
								<td
									class="text-truncate"
								>{{dados.filter(v => v.vendabase).map(v => v.vendabase).reduce((a,v) => a + v) |formataDinheiro}}</td>
								<td
									class="text-truncate"
								>{{dados.filter(v => v.comissaobase).map(v => v.comissaobase).reduce((a,v) => a + v) |formataDinheiro}}</td>
								<td
									class="text-truncate"
									v-if="dados.filter(v => v.comissaocoralegre > 0).length > 0"
								>{{dados.filter(v => v.vendacoralegre).map(v => v.vendacoralegre).reduce((a,v) => a + v) |formataDinheiro}}</td>
								<td
									class="text-truncate"
									v-if="dados.filter(v => v.comissaocoralegre > 0).length > 0"
								>{{dados.filter(v => v.comissaocoralegre).map(v => v.comissaocoralegre).reduce((a,v) => a + v) |formataDinheiro}}</td>
								<td
									class="text-truncate"
									v-if="dados.filter(v => v.comissaocampanha > 0).length > 0"
								>{{dados.filter(v => v.vendacampanha).map(v => v.vendacampanha).reduce((a,v) => a + v) |formataDinheiro}}</td>
								<td
									class="text-truncate"
									v-if="dados.filter(v => v.comissaocampanha > 0).length > 0"
								>{{dados.filter(v => v.comissaocampanha).map(v => v.comissaocampanha).reduce((a,v) => a + v) |formataDinheiro}}</td>
                <td
                    class="text-truncate"
                    v-if="dados.filter(v => v.comissaocampanhaob > 0).length > 0"
                >{{dados.filter(v => v.vendacampanhaob).map(v => v.vendacampanhaob).reduce((a,v) => a + v) |formataDinheiro}}</td>
                <td
                    class="text-truncate"
                    v-if="dados.filter(v => v.comissaocampanhaob > 0).length > 0"
                >{{dados.filter(v => v.comissaocampanhaob).map(v => v.comissaocampanhaob).reduce((a,v) => a + v) |formataDinheiro}}</td>
                <td class="text-truncate" v-if="dados.filter(v => v.vendatopcampanhaob > 0).length > 0"
                >{{dados.filter(v => v.vendatopcampanhaob).map(v => v.vendatopcampanhaob).reduce((a,v) => a + v) |formataDinheiro}}</td>
                <td
                    class="text-truncate"
                    v-if="dados.filter(v => v.comissaotopcampanhaob > 0).length > 0"
                >{{dados.filter(v => v.comissaotopcampanhaob).map(v => v.comissaotopcampanhaob).reduce((a,v) => a + v) |formataDinheiro}}</td>
                <td
                    class="text-truncate"
                >{{dados.filter(v => v.salario).map(v => v.salario).reduce((a,v) => a + v) |formataDinheiro}}</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</v-card-text>
				<v-card-text v-else class="text-center pt-8">
					<strong
						class="text-h6"
					>Selecione o mês no filtro acima, clique em buscar e aguarde o arquivo ser gerado para efetuar o download.</strong>
					<br />
					<small
						class="text-caption"
					>* Os vendedores devem ter suas metas lançadas para que ele seja exportado. Caso um vendedor não seja exportado verifique se ele teve sua meta lançada.</small>
					<br />
					<v-icon color="primary lighten-5" class="mt-5" size="150">mdi-folder-download-outline</v-icon>
				</v-card-text>
			</v-card>
		</v-col>
    <v-dialog v-model="aviso" width="1200px" persistent>
      <v-card>
        <v-card-title>
          Filiais com exportação bloqueada
          <v-spacer />
          <v-btn
              elevation="0"

              color="primary"
              @click="aviso=false"
              icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-alert text color="red" icon="mdi-information" class="ma-0 pt-5 pb-0">
          <p style="font-size: 0.8em">
            <strong>
              Identificamos vendas com produtos sem custo médio informado,
              esta situação ocasiona distorção da margem e consequentemente nos salários dos colaboradores,
              para que seja possível realizar a exportação do arquivo de comissões,
              o pricing deverá sanar os problemas listados abaixo:
            </strong>
          </p>
        </v-alert>
        <v-card-text>
          <v-data-table
            :headers="[
                { text: 'Código', value: 'idsubproduto', align:'left', class: 'pl-5', sortable: false},
                { text: 'Empresa', value: 'descricao', align:'left',  sortable: false},
                { text: 'Qtd. Vendas', value: 'qtdestoque', align:'center', sortable: false},
                { text: 'Qtd. Produtos', value: 'qtdestoque', align:'center', sortable: false},
            ]"
            hide-default-footer
          >

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "DepartamentoPessoalExportadorSenior",
	mixins: [mixinFilial],
	components: { InputDatePicker, CardDialog },
	data: () => ({
    aviso: false,
        snackbar: { status: false, timeout: 4000 },
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dados: [],
		busca: {
			dtmes: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}`,
			idfiliais: [],
		},
		carregando: false,
	}),
	computed: {
		...mapState(["backendUrl"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
                        v.idfilial != 22 &&
                        v.idfilial != 32 &&
						v.idfilial != 34
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		gerarArquivo() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			} else {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Selecione ao menos 1 filial para exportar!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.dialog = true;
			return axios
				.post(`${this.backendUrl}dp/arquivo`, {
					ano: this.busca.dtmes.split("-")[0],
					mes: this.busca.dtmes.split("-")[1],
					idfilial: idfiliais || null,
					responseType: "blob",
				})
				.then((res) => {
                    if (JSON.stringify(res.data) != "{}") {
                        this.dialog = false
                        if ( res.data.valores ) {
                            this.dados = res.data.valores;
                        }
                        const url = window.URL.createObjectURL(
                            this.base64ToBlob(res.data.arquivo)
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "arquivo.txt");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        this.dialog = false
                        this.snackbar.status = true;
                    }
				})
				.catch(() => {
					this.dialog = false
				});
		},
		base64ToBlob(base64) {
			const decodedData = window.atob(base64);
			const uInt8Array = new Uint8Array(decodedData.length);
			for (let i = 0; i < decodedData.length; ++i) {
				uInt8Array[i] = decodedData.charCodeAt(i);
			}
			return new Blob([uInt8Array], { type: "text/plain" });
		},
	},
	created() {},
};
</script>